.Row {
  display: flex;
  gap: 8px;
}

.Detail {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Detail strong {
  width: 25%;
  display: inline-block;
}

.Container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Alert {
  border-radius: 8px;
  background-color: rgba(255, 252, 47, 0.7);
  display: inline-flex;
  transition: all 1s ease;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
