.Container {
  background-color: var(--primmaryColor);
  color: white;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex: 1;
  gap: 16px;
}

.Container span {
  text-decoration: none;
  list-style: none;
  border: 1px solid white;
  display: inline-flex;
  margin: 4px 4px;
  padding: 4px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.1s ease-in;
}

.Container span:hover,
.Container span.Active {
  font-weight: bold;
  color: white;
  border-width: 2px;
}

.Column input {
  width: 100%;
}

@media (max-width: 768px) {
  .Container {
    padding: 8px;
    margin-bottom: 16px;
    flex-direction: row;
  }
}
