.ProductList {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.Item {
  cursor: pointer;
  border-bottom: 1px solid var(--primmaryColor);
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
