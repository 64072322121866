.Container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 16px 0;
  gap: 8px 48px;
  min-height: 480px;
}

@media (max-width: 768px) {
  .Container {
    grid-template-columns: 1fr 1fr;
    gap: 0 24px;
  }
}
