.Input {
  background-color: white;
  outline: 0;
  padding: 8px;
  width: 100%;
  border: 1px solid #eee;
  margin: 4px 0;
  font-size: 16px;
}

.Input:focus {
  border: 2px solid var(--primmaryColor);
}
