.Card {
  width: 860px;
  height: 85%;
  border-radius: 8px;
  padding: 24px;
  background-color: white;
  cursor: auto;
  overflow-y: auto;
  position: relative;
}

.Info {
  display: flex;
  gap: 8px;
  height: 100%;
}

.Close {
  background-color: var(--primmaryColor);
  border-radius: 100px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: white;
  margin-left: auto;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.Column {
  flex: 1;
}

.Column > * {
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Column h1 {
  background-color: var(--primmaryColor);
  color: white;
  display: inline-block;
  border-radius: 8px;
  padding: 8px;
  font-size: 24px;
}

.ListItem {
  padding: 16px 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  font-size: 16px;
  transition: 0.3s;
}

.ListItem:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.ListItem strong {
  display: block;
  color: var(--primmaryColor);
}

.ListItem li {
  list-style-position: inside;
}

.Square {
  margin-right: 8px;
  width: 15px;
  height: 15px;
  border: 1px solid black;
  display: inline-block;
}

.Focus {
  width: 45%;
}

.Focus img {
  width: 100%;
  height: 320px;
  object-fit: contain;
}

.Row {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.Row div {
  width: 33%;
  height: 120px;
  cursor: pointer;
  border: 1px solid #eee;
}

.Row img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 768px) {
  .Card {
    padding: 8px;
    width: 80%;
    min-width: unset;
  }

  .Column {
    width: 100%;
  }

  .Info {
    flex-direction: column;
  }

  .Focus {
    width: 100%;
  }

  .Focus img {
    height: unset;
  }
}
