.Container {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  padding: 24px 0;
  margin-bottom: 160px;
}

.Btns {
  display: flex;
  gap: 8px;
}

.About {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ccc;
  display: flex;
  gap: 8px;
  width: 100%;
  max-height: 370px;
  overflow: auto;
}

.Title {
  color: #487292;
}

.About svg {
  cursor: pointer;
}

.About textarea {
  width: 100%;
  font-size: 16px;
  height: 120px;
  padding: 0;
  resize: vertical;
}

.About input {
  font-size: 18px;
  padding: 0;
  border: none;
  border-bottom: 1px solid black;
  display: block;
}

.About input,
.About textarea,
.About select {
  margin-bottom: 4px;
}

.About a {
  font-size: 12px;
  text-decoration: underline;
  color: blue;
}
