.Container {
  background-color: #20232a;
  color: white;
  padding: 24px var(--horizontalMargin);
}

.Info {
  display: flex;
  gap: 16px;
}

.Column img {
  background-color: white;
  width: 240px;
  border-radius: 8px;
}

.Column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 8px;
  font-size: 14px;
}

.Bar {
  background-color: white;
  height: 1px;
  width: 100%;
  margin: 16px 0;
}

@media (max-width: 768px) {
  .Info {
    flex-direction: column;
    gap: 8px;
  }
}
