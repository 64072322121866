.Info {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 12px;
  flex: 33.3333333333%;
  height: 170px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  position: relative;
  top: 0;
  left: 0;
  transition: all 1s ease;
  overflow-x: hidden;

  animation-name: example;
  animation-duration: var(--randTime);
  animation-timing-function: ease-in;
}

@keyframes example {
  from {
    top: -200px;
  }
  to {
    top: 0;
  }
}

.Info svg {
  font-size: 1.5rem;
  margin: auto;
  display: block;
}

.Focus {
  animation: example 1s, shake 1s;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.Info:hover {
  background-color: var(--primmaryColor);
}

.Info p {
  white-space: pre-line;
  text-align: justify;
  font-size: 16px;
  margin: 24px 0;
  position: relative;
  z-index: 10;
  display: none;
}

.Info p strong {
  font-weight: bold;
  font-size: 18px;
}

.Info h2 {
  font-size: 20px;
  text-align: center;
  position: relative;
  z-index: 10;
}

.Expanded {
  top: -100%;
}

.ShowText p {
  display: block;
}

.Info img {
  width: 60%;
  margin: auto;
  margin-bottom: 16px;
  display: block;
}

.Green {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.3;
}

.Green img {
  width: 100%;
  height: 100%;
  margin: 0;
}

.Green2 img {
  width: 40%;
  margin: auto;
  position: relative;
  z-index: 10;
}

@media (max-width: 768px) {
  .Info {
    flex: 100%;
    height: 96px;
    padding: 4px 12px;
  }

  .Info p {
    font-size: 14px;
  }

  .Info h2 {
    font-size: 16px;
  }
}
