.Container {
  position: relative;

  display: inline-flex;
  flex-direction: column;

  border-radius: 8px;
  height: 320px;
  cursor: pointer;
  overflow: hidden;

  animation-name: example;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes example {
  from {
    top: var(--initial-top);
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.SubContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  height: 48px;

  font-size: 16px;
  color: white;
  text-align: center;
}

.Container:hover .SubContainer {
  background-color: var(--primmaryColor);
}

.ImageLoading {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ImageContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ImageContainer img {
  width: 100%;
  max-height: 272px;
}
