.About {
  margin-top: var(--navbarHeight);
  background-image: url("../../assets/home/2_DSC9786.jpeg");
  background-size: cover;
  display: flex;
  min-height: 480px;
}

.Column {
  flex: 1;
  color: white;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.Column h1 {
  width: 100%;
  font-size: 32px;
  background-color: var(--primmaryColor);
  text-align: center;
}

@media (max-width: 768px) {
  .Column h1 {
    font-size: 24px;
  }
}
