.Container {
  margin: 0 var(--horizontalMargin);
  margin-top: var(--navbarHeight);
  display: flex;
  flex-direction: column;
  padding: 32px 0;
}

@media (max-width: 768px) {
}
