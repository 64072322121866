.Header {
  margin-top: var(--navbarHeight);
  height: 480px;
  position: relative;
  margin-bottom: 80px;
}

.Column {
  background-color: var(--primmaryColorOpacity);
  border-radius: 0 160px 160px 0;
  width: 480px;
  padding: 24px;
  color: white;
  position: absolute;
  bottom: -64px;
  left: 0;
  z-index: 10;
}

.Column h1 {
  font-family: "Raleway", sans-serif;
}

.Album {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;
  background-color: white;
}

.ImgContainer {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: left 0.75s ease, opacity 2.5s ease;
}

.Img {
  position: absolute;
  width: 90%;
  margin: 0 5%;
  height: 100%;
  object-fit: cover;
  z-index: 5;
}

.BgImg {
  margin: 0;
  width: 100%;
  z-index: 3;
  filter: blur(5px);
  object-fit: cover;
}

.ColumnRight {
  position: absolute;
  display: flex;
  right: 0;
  bottom: -20px;
  z-index: 10;
  gap: 12px;
}

.Ball {
  border-radius: 80px;
  height: 10px;
  width: 10px;
  z-index: 100;
  background-color: var(--primmaryColor);
}

.Active {
  background-color: transparent;
}

@media (max-width: 768px) {
  .Column {
    border-radius: 0;
    width: 100%;
  }

  .Img {
    object-fit: cover;
  }

  .Column h1 {
    font-size: 24px;
  }

  .Column p {
    font-size: 16px;
  }

  .Header {
    height: 360px;
  }

  .ColumnRight {
    top: 20px;
    left: 0;
    right: 0;
    justify-content: center;
    bottom: unset;
  }
}
