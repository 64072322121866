.Nav {
  display: flex;
  padding: 0 var(--horizontalMargin);
  height: 100px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: white;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
}

.ImgContainer {
  height: 100px;
}

.ImgContainer img {
  height: 100%;
}

a {
  text-decoration: none;
  color: black;
  text-align: center;
}

.LinkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  min-height: 64px;
  text-align: center;
}

.Group {
  display: flex;
  margin: 0 auto;
}

.LinkContainer:hover,
.Active {
  transition: border-width 0.1s ease-in;
  border-bottom: 3px solid var(--primmaryColor);
  color: var(--primmaryColor);
  cursor: pointer;
}

@media (max-width: 768px) {
  .Nav {
    flex-wrap: wrap;
    height: 180px;
  }

  .ImgContainer {
    width: 50%;
  }

  a {
    font-size: 14px;
  }

  .Group {
    width: 100%;
    margin-top: -24px;
    order: 99;
  }

  .LinkContainer {
    width: unset;
    flex: 1;
  }
}
