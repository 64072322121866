.Admin {
  margin-top: var(--navbarHeight);
  margin-bottom: 100px;
  padding: 0 var(--horizontalMargin);
  padding-top: 32px;
}

.Links {
  display: flex;
  gap: 16px;
}

.Links a {
  color: blue;
  text-decoration: underline;
}
