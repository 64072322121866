.Contact {
  margin-top: var(--navbarHeight);
  padding: 32px var(--horizontalMargin);
  margin: 220px 0;
}

.Row {
  display: flex;
  gap: 16px;
}

.Column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.Column img {
  height: 85%;
  cursor: pointer;
  opacity: 0.8;
  margin: auto;
  transition: all 0.5s ease-in-out;
}

.Column img:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .Row {
    flex-direction: column-reverse;
  }

  .Column img {
    height: unset;
    width: 100%;
  }
}
