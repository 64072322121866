html,
body,
#root,
.App {
  height: 100%;
  scroll-behavior: smooth;
}

.App * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  --horizontalMargin: 80px;
  --navbarHeight: 100px;
  --primmaryColor: #09416cbd;
  --primmaryColorOpacity: #09416cbd;
}

h1,
h2,
h3 {
  font-family: "Arial", sans-serif;
}

p,
a,
button,
input,
textarea {
  font-family: "Open Sans", sans-serif;
}

@media (max-width: 768px) {
  .App * {
    --horizontalMargin: 12px;
    --navbarHeight: 180px;
  }
}
